.container {
    position: relative;
    border-right: 1px solid var(--accent-7);
    padding: var(--space-6) !important;
    background-color: white;
    z-index: 10;

    &.static {
        position: fixed;
        top: 0;
        left: 0;
        width: 272px;
        height: 100%;
    }

    &.hide {
        margin-left: -250px;
    }

    .menu-button {
        position: absolute;
        right: 0%;
        transform: translateX(50%);
    }

    .nav-link {
        text-decoration: none;

        .text {
            text-align: center;
            font-weight: 600;
            color: black;
            padding: var(--space-2) var(--space-2);

            &:hover {
                color: var(--green-9);
            }

            &.active {
                color: var(--accent-11);

                &:hover {
                    color: var(--accent-12);
                }
            }

            .icon {
                text-align: center;
                font-size: 1rem;
                font-weight: 600;
                margin-right: var(--space-1);
                color: var(--green-9);
                padding: var(--space-1) var(--space-2);
                border-radius: var(--radius-2);

                &.active {
                    color: var(--accent-11);
                    background-color: var(--accent-3);

                    &:hover {
                        color: var(--accent-12);
                    }
                }
            }
        }
    }
}
