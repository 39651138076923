.container {
    background-color: var(--accent-3);
    height: 100vh;
    overflow: hidden;

    .image {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation-name: fadeZoomOut;
        animation-duration: 10s;
        animation-timing-function: ease-out;
    }

    .overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(9, 78, 33, 0.798);

        &.is-error {
            background-color: rgba(78, 9, 9, 0.798);
        }
    }

    .content {
        color: white;

        .title {
            font-size: 2rem;
            line-height: 2rem;
            font-weight: 700;
        }

        .green-point {
            font-size: 6rem;
            line-height: 6rem;
        }

        .subtitle {
            font-size: 1.4rem;
            line-height: 1.4rem;
            font-weight: 700;
        }
    }
}

@keyframes fadeZoomOut {
    0% {
        opacity: 0.6;
        transform: scale(1.4);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
