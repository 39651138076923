.input-quantity {
	border: none;
	text-align: center;
	padding: 0 0.2rem;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&:focus {
		outline: none;
	}
}
