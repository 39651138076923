.map-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    :global(.leaflet-container) {
        width: 100%;
        height: 100%;

        * {
            transition: none !important;
        }
    }
}

.control {
    z-index: 1;
}
